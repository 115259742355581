import React from "react"
import styled from "@emotion/styled"
import AmericanEagleImg from "../images/americanEagle.svg"
import SmartShelfImg from "../images/smartShelf.svg"
import EvolveSecImg from "../images/evolveSec.svg"
import MicrosoftImg from "../images/microsoft.svg"
import HuluImg from "../images/hulu.svg"
import LogosCompaniesImg from "../images/logos-companies.png"
import { Section } from "../Styles/section"
import { HeadingSecondary } from "../Styles/headers"
import { SectionHero } from "../Styles/text"

const Results = styled.div`
  margin: auto;
  max-width: 1200px;
  background: ${props => props.theme.colors.grey100};
`

const Brands = styled.div`
  display: flex;
  z-index: 99;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: ${props => props.theme.pm.pm300} 0;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.mobile}) {
    flex-direction: column;
    padding: 0 0 ${props => props.theme.pm.pm300} 0;
  }
`

const BrandImg = styled.img`
  width: 30%;
  height: 35px;
  margin: ${props => props.theme.pm.pm100} 1.65%;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`

const TempLogosImg = styled.img`
  width: 90%;
  display: block;
  margin: 2rem auto;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`

export default function brands() {
  return (
    <Section backgroundColor="secondary">
      <Results>
        <HeadingSecondary css={{ textAlign: "center" }}>
          Results
        </HeadingSecondary>
        <SectionHero>
          Over the years, I’ve mentored many people, helped them transition
          their career into tech and land their first jobs/clients. They've
          landed roles at companies like...
        </SectionHero>
        <Brands>
          <BrandImg src={AmericanEagleImg} alt="brand logo" />
          <BrandImg src={SmartShelfImg} alt="brand logo" />
          <BrandImg src={EvolveSecImg} alt="brand logo" />
          <BrandImg src={MicrosoftImg} alt="brand logo" />
          <BrandImg src={HuluImg} alt="brand logo" />
        </Brands>

        {/* <TempLogosImg src={LogosCompaniesImg} alt="companies logos" /> */}
      </Results>
    </Section>
  )
}
