import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { theme } from "./theme"
export const BtnMedium = styled.a`
  background: ${props => props.theme.colors.primary};
  padding: ${props => props.theme.pm.pm100} ${props => props.theme.pm.pm200};
  color: #fff;
  display: inline-block;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: ${props => props.theme.text.text200};
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.15);
`
export const BtnOutline = css`
  padding: ${theme.pm.pm100} ${theme.pm.pm200};
  color: #f6f6f6;
  display: inline-block;
  border: solid 1px;
  cursor: pointer;
  background: none;
  font-size: ${theme.text.text200};
  outline: none;
`

export const AlertBtn = css`
  border: none;
  border-radius: 3px;
  background: ${theme.colors.primary};
  margin-bottom: ${theme.pm.pm300};
  box-shadow: $direct-light-soft;
  padding: ${theme.pm.pm100};
  width: 100%;
  display: block;
  max-width: 400px;
  color: #fff;
  font-size: ${theme.text.text200};
  text-align: center;
`

export const BtnLarge = styled.a`
  background: #2d80fa;
  padding: ${theme.pm.pm200} ${theme.pm.pm300};
  color: #fff;
  display: inline-block;
  border: none;
  font-size: ${theme.text.text400};
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.15);
`
