import styled from "@emotion/styled"

export const SectionHero = styled.p`
  font-size: 1.4rem;
  color: ${props =>
    props.color === "onDark" ? "#d6d6d6" : props.theme.colors.grey900};
  margin: 1rem 0 1rem;
  line-height: 1.5;
  font-weight: 300;
  span {
    color: ${props => props.theme.colors.grey900};
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.mobile}) {
    font-size: ${props => props.theme.text.text300};
  }
`

export const SectionTagLine = styled.p`
  font-size: ${props => props.theme.text.text400};
  color: ${props => props.theme.colors.grey900};
  font-weight: bold;
  margin-bottom: ${props => props.theme.pm.pm200};
  line-height: 1.5;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.mobile}) {
    font-size: ${props => props.theme.text.text200};
    margin-bottom: ${props => props.theme.pm.pm200};
  }
`
