import React, { Component, useState } from "react"
import styled from "@emotion/styled"
import { theme } from "../Styles/theme"
import _ from "lodash"
import { HeadingSecondary } from "../Styles/headers"
import { SectionHero } from "../Styles/text"
import { navigate } from "gatsby"
import { BtnOutline } from "../Styles/buttons"
import { AlertBtn } from "../Styles/buttons"
import TwoGirls from "../images/two-girls.jpg"

const ModalInner = styled.div`
  background: white;
  width: 100%;
  height: 100%;
`

// background: url('${TwoGirls}');
const ModalTop = styled.div`
    background: ${theme.colors.primary};
    background-size: cover;
    position: relative;
    padding: 1.4rem .8rem;
  `
const ModalClose = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  background: #333;
  width: 27px;
  color: #ddd;
  verticle-align: middle;
  text-align: center;
  border-radius: 50%;
  font-size: 2rem;

  &:hover {
    cursor: pointer;
  }
`
const ModalContent = styled.div`
  text-align: center;
  background: white;
  max-width: 90%;
  margin: 0 auto;
  opacity: 0.95;
  padding: 1.4rem 2rem;

  p {
    margin: 1rem 0;

    &:first-child {
      font-weight: bold;
    }
  }
`
const ModalBottom = styled.div`
  background: #f7fafc;
`
const ModalForm = styled.form`
  display: flex;
  max-width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  padding: 1rem 0.8rem;
`
const ModalFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 31%;

  label {
  }
  input {
    font-size: 1rem;
    text-align: start;
    vertical-align: middle;
    width: 100%;
    padding: 1rem;

    &:focus {
      outline: none !important;
      border: 2px solid #8d9aa7;
    }
  }
`
const ModalFormSubmit = styled.button`
  width: 31%;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  border: none;
  background: ${theme.colors.primary};
  color: white;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity: 0.95;
  }
`
export default function ModalFormPop(props) {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [wrongMail, setWrongMail] = useState("")

  console.log(name)
  console.log(email)

  const handleChange = e => {
    switch (e.target.id) {
      case "input-name":
        setName(e.target.value)
        console.log(name)
        break
      case "input-email":
        setEmail(e.target.value)
        break
      default:
        console.log("something went wrong")
    }
  }

  const newSubscriber = e => {
    e.preventDefault()

    if (name && email) {
      const url = `/.netlify/functions/optin-fed?name=${name}&email=${email}`
      fetch(url)
        .then(res => res.json())
        .then(data => {
          if (data.error) {
            setWrongMail(data.message)
            setName("")
            setEmail("")
            setTimeout(_ => {
              setWrongMail("")
            }, 2000)
          } else {
            setName("")
            setEmail("")
            navigate("/optin-confirm--fed/")
            document.body.style.overflow = "auto"
          }
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <ModalInner>
      <ModalTop>
        <ModalClose onClick={_.debounce(props.closeModal, 300)}>
          &times;
        </ModalClose>
        <ModalContent>
          <p>FREE DOWNLOAD</p>
          <HeadingSecondary style={{ lineHeight: '1.2'}}>
            {/* Looking for strategies to get accepted into your dream school? */}
            Frontend Developer Roadmap Guide (80+ Slides PDF)
          </HeadingSecondary>
          <p>
            {/* This free (and highly detailed) cheat sheet will give you 20 smart
            strategies to help you grow your email list (AND give you a shot of
            confidence to continue attracting your perfect audience!). */}
            This guide will cover everything you need to become a frontend developer.
          </p>
        </ModalContent>
      </ModalTop>
      <ModalBottom>
        <ModalForm onSubmit={newSubscriber}>
          <ModalFormGroup>
            <label>First Name</label>
            <input
              type="text"
              key="lorem"
              name="name"
              id="input-name"
              value={name}
              onChange={handleChange}
              required
            />
          </ModalFormGroup>
          <ModalFormGroup>
            <label>Email</label>
            <input
              type="email"
              key="ipsum"
              name="name"
              id="input-email"
              value={email}
              onChange={handleChange}
              required
            />
          </ModalFormGroup>
          {wrongMail !== "" ? <button>{wrongMail}</button> : ""}
          <ModalFormSubmit type="submit">Get 80+ Slide Guide</ModalFormSubmit>
        </ModalForm>
      </ModalBottom>
    </ModalInner>
  )
}
