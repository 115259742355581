import React, { Component, useState } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import { theme } from "../Styles/theme"
import _ from "lodash"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { HeadingTertiary } from "../Styles/headers"
import { SectionHero, SectionTagLine } from "../Styles/text"
import { Section } from "../Styles/section"
import BabyImg from "../images/baby.svg"
import useModal from "./useModal"
import Img from "gatsby-image"
import ModalFormPop from './modalForm';

const CtaMedium = css`
  background: linear-gradient(to right, #1c84a6, #3cb371);
  padding: ${theme.pm.pm100} ${theme.pm.pm200};
  color: #fff;
  display: inline-block;
  border: none;
  font-weight: bold;
  border-radius: 2px;
  font-size: ${theme.text.text300};
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.15);
`

const BtnMedium = css`
  background: ${theme.colors.primary};
  padding: ${theme.pm.pm100} ${theme.pm.pm200};
  color: #fff;
  display: inline-block;
  border: none;
  font-size: ${theme.text.text200};
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.15);
`

const HeaderSection = styled.div`
  color: ${theme.colors.grey900};
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  margin-top: 5rem;
  line-height: 1.4;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: calc(${props => props.theme.sizes.navbarHeight} + 1.5rem);
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;

    h3 {
      line-height: 1.4;
    }
  }
`

const HeaderImg = css`
  width: 100%;
  max-width: 400px;
  display: block;
  margin: auto;
  box-shadow: ${theme.boxShadows.images};

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    display: none;
  }
`

const HeaderCol = styled.div`
  height: 100%;
  padding: 1rem;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    width: 100%;
    height: auto;
  }
`
const BabyIcon = styled.img`
  max-height: 100%;
  position: absolute;
  bottom: 2%;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    bottom: 0;
  }
`
const Highlighted = styled.span`
`

export default function Header() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/tenzin-home.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { openModal, closeModal, isOpen, Modal } = useModal({
    background: "rgba(0, 0, 0, 0.8)",
    onOpen: event => {
      // can access: event.portal, event.targetEl, event.event, event.target, etc.
      document.body.style.overflow = "hidden"
    },
    // `onClose` will not have an `event` unless you pass an `event` to `closePortal`
    onClose({ portal, targetEl, event }) {
      document.body.style.overflow = "auto"
    },
  })

  return (
    <Section backgroundColor="main">
      <HeaderSection>
        {/* <HeaderCol>
          <Img
            css={HeaderImg}
            fluid={data.file.childImageSharp.fluid}
            alt="Tenzin"
          />
        </HeaderCol> */}
        {/* <HeaderCol> */}
          <HeadingTertiary style={{ margin: "0px 0 4px"}}>
            Tech Career, Entrepreneurship, and Personal Growth            {/* <span role="img" aria-label="excitment">
              <BabyIcon src={BabyImg} alt="newborn javascript" />
            </span> */}
          </HeadingTertiary>
          {/* <SectionTagLine></SectionTagLine> */}
         <SectionHero>
            {/* I help people become{" "}
            <Highlighted>successful&nbsp;developers.</Highlighted> */}
            Looking to break into the tech industry and land your first dev role? Or an experienced dev ready to ditch the 9-to-5 and kickstart your own thing – freelancing, running an agency, or creating a personal brand? 
            <br />
            Been there, done that. 
            <br />
            Get no-nonsense guides, playbooks, tested strategies, and real success stories every week.
          </SectionHero>
          {/* <SectionHero>
            Start here and get insights in the intersection of web development, entrepreneurship and self improvement. 
          </SectionHero> */}
          {/* <Link css={CtaMedium} to="/community">
            Learn More
          </Link> */}
          <br />

          <button css={BtnMedium} onClick={openModal} style={{fontSize:"1.2rem"}}>
            Gimme the goods
          </button>
          {isOpen && (
            <Modal>
              <ModalFormPop closeModal={closeModal} />
            </Modal>
          )}
        {/* </HeaderCol> */}
      </HeaderSection>
    </Section>
  )
}
