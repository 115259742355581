import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import { theme } from "../Styles/theme"
import { Section } from "../Styles/section"
import { useStaticQuery, graphql } from "gatsby"
import { HeadingSecondary } from "../Styles/headers"
import { SectionHero } from "../Styles/text"
import TenzinPic from "../images/tenzin-home.jpg"

const Cta = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 6rem 2rem;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    flex-direction: column;
    padding: 2rem 2rem;
  }
`

const CtaProfileImg = styled.img`
  width: 100%;
  height: auto;
  display: block;
  border-radius: 50%;
`

const CtaProfileWrapper = styled.div`
  max-width: 250px;
  width: 45%;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    max-width: 120px;
  }
`

const CtaInfo = styled.div`
  width: 45%;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    width: 100%;
    margin-top: 2rem;
  }
`

const CtaBody = styled.p`
  font-size: 1.4rem;
  color: ${theme.colors.grey900};
  line-height: 1.3;
  margin-bottom: 32px;
`

const BtnMedium = css`
  background: ${theme.colors.primary};
  padding: ${theme.pm.pm100} ${theme.pm.pm200};
  color: #fff;
  display: inline-block;
  border: none;
  font-size: 0.9rem;
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.15);
`


export default function cta() {
    const data = useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "images/joanna-orchard.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  return (
    <Section backgroundColor="secondary">
      <Cta>
        <CtaProfileWrapper>
          <CtaProfileImg src={TenzinPic} alt="Joanna Picture">
          </CtaProfileImg>
        </CtaProfileWrapper>
        <CtaInfo>
          <h3>Want to work with me?</h3>
          <Link css={BtnMedium} to="/contact">
            Schedule a FREE Consultation
          </Link>
        </CtaInfo>
      </Cta>
    </Section>
  )
}
